import { BigNumber } from '@ethersproject/bignumber';
import { useRouteMatch } from 'react-router-dom';
import { useSuit } from './hooks/useSuit';
import { SuitContainer } from './viewer/SuitContainer';
import { SuitViewer } from './viewer/SuitViewer';

export function SuitRoute() {
  const route = useRouteMatch<{ tokenId: string }>();
  const tokenId = parseInt(route.params.tokenId, 10);
  const suit = useSuit(BigNumber.from(tokenId));

  return <SuitViewer suit={suit} />;
}

export function VersionRoute() {
  const route = useRouteMatch<{ metadataId: string; batch: string }>();
  return <SuitContainer {...route.params} />;
}
