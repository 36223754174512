import { Box, useBreakpoint } from '@chakra-ui/react';
import { Canvas } from '@react-three/fiber';
import React from 'react';
import ReactDOM from 'react-dom';

const BackgroundScene = React.lazy(() => import('./BackgroundScene'));

export function BackgroundCanvas() {
  const underlay = document.getElementById('underlay')!;
  const value = useBreakpoint() || 'base';

  return value === 'base'
    ? null
    : ReactDOM.createPortal(
        <Box height="100vh" width="100vw" bg="#141313">
          <Canvas>
            <BackgroundScene />
          </Canvas>
        </Box>,
        underlay,
      );
}
