import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCThneKkZyyXEXT5z9bCYrd8YK-QDbzN_E',
  authDomain: 'heavy-suit.firebaseapp.com',
  projectId: 'heavy-suit',
  storageBucket: 'heavy-suit.appspot.com',
  messagingSenderId: '1069007446190',
  appId: '1:1069007446190:web:e8e67241269c0c17d54d3f',
  measurementId: 'G-ZYP42FJ71C',
};

export const firebase = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebase);
