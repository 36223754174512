import { Box, chakra } from '@chakra-ui/react';
import { intervalToDuration } from 'date-fns';
import { useCountdown } from './hooks/useCountdown';

export function Separator() {
  return <chakra.span>:</chakra.span>;
}

export function Numbers(props: { value: number; color?: string }) {
  return (
    <chakra.span
      color={props.color}
      textAlign="center"
      width="46px"
      display="inline-block"
    >
      {String(props.value).padStart(2, '0')}
    </chakra.span>
  );
}

export function Countdown() {
  const { timeToReveal, timeToStart, now } = useCountdown();

  const countdown = timeToStart || timeToReveal;

  if (!countdown) {
    return null;
  }

  const { days, hours, minutes, seconds } = intervalToDuration({
    start: new Date(now),
    end: new Date(now + countdown),
  });

  return (
    <Box
      background="linear-gradient(
      -84deg, 
      transparent 3%,
      #bbb 4%,
      #bbb 96%,
      transparent 97%)"
      color="#141313"
      transform="scale(0.8)"
      px="4"
      py="2"
      visibility="hidden"
    >
      <Box fontWeight="bold" textTransform="uppercase">
        {timeToStart ? 'Token Sale' : 'Metadata Reveal'}
      </Box>
      <Box
        fontFamily="Kanit"
        fontWeight="700"
        fontSize="32px"
        lineHeight="30px"
      >
        <Numbers value={days || 0} />
        <Separator />
        <Numbers value={hours || 0} />
        <Separator />
        <Numbers value={minutes || 0} />
        <Separator />
        <Numbers value={seconds || 0} />
      </Box>
    </Box>
  );
}
