import { ethers } from 'ethers';

const rpcURL = process.env.REACT_APP_JSON_RPC;
export const hasMetaMask = !!(window as any).ethereum;

if (!rpcURL) {
  // throw new Error('Missing REACT_APP_JSON_RPC');
  console.log('missing')
}

export const metamask = hasMetaMask
  ? (window as any).ethereum
  : { on: () => {} };
export const provider = hasMetaMask
  ? new ethers.providers.Web3Provider(metamask)
  : new ethers.providers.JsonRpcProvider(rpcURL);
