import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Text,
  useBreakpoint,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import ReactDOM from 'react-dom';
import { SuitData } from '../hooks/useSuit';
import { TokenMetadata } from '../shared/TokenMetadata';
import { AttributeDisplay } from './AttributeDisplay';

const ModelViewer = React.lazy(() => import('./ModelViewer'));

const MotionBox = motion(Box);
const MotionStack = motion(Stack);

function SuitViewerHeader(props: { name: string; description: string }) {
  return (
    <MotionBox
      key={props.name}
      transitionDelay="400ms"
      color="#edf2f4"
      exit={{ opacity: 0 }}
      initial={{
        y: -20,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1.0,
      }}
      transitionDuration="400ms"
      maxW={{ base: '100vw', md: '480px' }}
      background={{
        base: '#141313',
        md: `linear-gradient(-84deg, transparent 4%, #141313 4.5%, #141313 100%)`,
      }}
      position="absolute"
      zIndex="10"
      top={{ base: '158px', md: '176px', lg: '60px' }}
      left={{ base: '0', lg: '250px' }}
    >
      <Box pl="4" pr="8" py="4">
        <Text fontWeight="700" fontSize={{ base: 'lg', lg: '3xl' }}>
          {props.name}
        </Text>
        <Text fontSize={{ base: '8pt', lg: 'sm' }}>{props.description}</Text>
      </Box>
    </MotionBox>
  );
}

function SuitViewerMeta({ metadata }: { metadata: TokenMetadata }) {
  return (
    <MotionStack
      key={metadata.name}
      exit={{ opacity: 0 }}
      transitionDelay="400ms"
      initial={{
        y: 20,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1.0,
      }}
      color="#edf2f4"
      flexDirection={{ base: 'row', md: 'row', '2xl': 'column' }}
      width={{ base: '100%', md: 'auto' }}
      spacing="0"
      bg="#141313"
      p="4"
      position="absolute"
      zIndex="-2"
      bottom="0"
      right="0"
    >
      <Box pr="8">
        {metadata.attributes
          .filter((a) =>
            [
              'Date of Manufacture',
              'Place of Manufacture',
              'Height',
              'Weight',
            ].includes(a.trait_type),
          )
          .map((a) => (
            <AttributeDisplay attribute={a} key={a.trait_type} />
          ))}
      </Box>
      <Box pr="8">
        {metadata.attributes
          .filter((a) =>
            ['Head', 'Torso', 'Left Arm', 'Right Arm', 'Legs'].includes(
              a.trait_type,
            ),
          )
          .map((a) => (
            <AttributeDisplay attribute={a} key={a.trait_type} />
          ))}
      </Box>
    </MotionStack>
  );
}

export function SuitViewer(props: { suit: SuitData | null }) {
  const underlay = document.getElementById('underlay')!;
  const point = useBreakpoint() || 'base';

  const { suit } = props;
  if (!suit) {
    return <CircularProgress />;
  }

  if (!suit || !suit.metadata) {
    return <Box>This Heavy Suit is not ready yet</Box>;
  }

  const { metadata } = suit;

  return (
    <>
      <ModelViewer
        src={metadata.animation_url}
        alt={metadata.name}
        key={suit.id.toString()}
      />
      <Box>
        {ReactDOM.createPortal(
          <Box opacity={{ base: 0.8, md: 1.0 }}>
            <SuitViewerHeader {...metadata} />
            <SuitViewerMeta metadata={metadata} />
          </Box>,
          underlay,
        )}
        <Button
          as="a"
          href="https://testnets.opensea.io/collection/heavy-suit"
          target="_blank"
          size={point === 'base' ? 'xs' : 'md'}
          position="absolute"
          top={{ base: '164px', md: '178px', lg: '200px' }}
          mt="2"
          ml="4"
          right={{ base: '2', lg: 'auto' }}
          left={{ base: 'auto', lg: 'auto' }}
          zIndex="1000"
          pointerEvents="auto"
          colorScheme="red"
          bg="brand.red"
        >
          OpenSea Testnet
        </Button>
      </Box>
    </>
  );
}
