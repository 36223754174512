import { Box, Center, Image, Spinner } from '@chakra-ui/react';
import './App.scss';
import './firebase';

export function Loading() {
  return (
    <Center height="100vh">
      <Box position="relative">
        <Image
          src="/images/spade.svg"
          height="24px"
          position="absolute"
          top="10.5px"
          left="14px"
          className="blink"
        />
        <Spinner color="#e5383b" size="xl" />
      </Box>
    </Center>
  );
}
