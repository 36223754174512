import {
  Box,
  Button,
  chakra,
  HStack,
  Icon,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import { Link as RouterLink } from 'react-router-dom';
import { Countdown } from './Countdown';
import Header from './Header';
import { Logo } from './Logo';
import { sectionLocations } from './sectionLocations';
import { BackgroundCanvas } from './three/BackgroundCanvas';

const MotionBox = motion(Box);

const parentVariant = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1.0,
    transition: {
      when: 'beforeChildren',
      duration: 0.8,
      delay: 0.1,
    },
  },
};

function getChildVariant(i: number) {
  return {
    hidden: { opacity: 0, x: -20 },
    show: {
      opacity: 1.0,
      x: 0,
      transition: {
        duration: 0.4,
        delay: i * 0.2,
      },
    },
  };
}

function ScrollPrompt() {
  return (
    <MotionBox
      variants={getChildVariant(4)}
      mt="12"
      color="#edf2f4"
      position="relative"
    >
      <Image
        src="/images/scroll-prompt.svg"
        alt="Scroll"
        position="absolute"
        width="40px"
        filter="drop-shadow(0 0 6px #e5383b)"
      />
      <Icon
        as={FaCaretDown}
        height="16px"
        position="absolute"
        top="13px"
        left="13px"
        className="floating"
        textShadow="0 0 10px #e5383b"
      />
      <Box
        top="10px"
        left="36px"
        position="absolute"
        letterSpacing="0.1rem"
        fontWeight="700"
        textTransform="uppercase"
      >
        Scroll to explore
      </Box>
    </MotionBox>
  );
}

function HeroUnit() {
  return (
    <MotionBox
      variants={parentVariant}
      id="section1"
      minH={{ base: '600px', md: '100vh' }}
      position="relative"
      overflow="hidden"
      background={{ base: '#141313', md: 'transparent' }}
    >
      <Box
        position="absolute"
        top={{ base: '100px', md: '15%' }}
        left={{ base: '5%', md: '15%' }}
        transform={{ base: '', md: 'perspective(1500px) rotateY(15deg)' }}
        width={{ base: '80%', md: '600px' }}
      >
        <Box
          px="0"
          fontWeight="700"
          fontSize={{ base: '50px', md: '100px' }}
          lineHeight={{ base: '40px', md: '80px' }}
          position="relative"
        >
          <MotionBox
            variants={getChildVariant(0)}
            css={{
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
            background="radial-gradient(circle at 12% 110%, #fa007c 20%, #8338ec 30%, #1c1c1c 50%)"
          >
            COLLECT
            <br />
            CUSTOMIZE
          </MotionBox>
          <MotionBox
            variants={getChildVariant(2)}
            pb="4px"
            pt="0"
            pr="8px"
            pl="4px"
            mt="8px"
            ml="5px"
            color="gray.800"
            css={{
              WebkitTextFillColor: 'gray.800',
              WebkitBackgroundClip: 'unset',
            }}
            display="inline-block"
            background="radial-gradient(circle at 28% -20%, #fa007c 30%, #8338ec 60%, #1c1c1c 100%)"
          >
            FIGHT
          </MotionBox>
        </Box>
        <MotionBox
          variants={getChildVariant(3)}
          pb="4px"
          pt="0"
          pr="8px"
          pl="4px"
          mt="4"
          ml="5px"
          fontWeight="700"
          fontSize="2xl"
          color="brand.gray"
          display="inline-block"
        >
          Unique mecha NFTs for the 3D metaverse
        </MotionBox>
        <ScrollPrompt />
      </Box>
    </MotionBox>
  );
}

function SectionHeader(props: { text: string }) {
  return (
    <VStack>
      <Box
        className="hero glitch layers"
        data-text={props.text.toUpperCase()}
        fontStyle="italic"
        mb="8"
      >
        {props.text.toUpperCase()}
      </Box>
    </VStack>
  );
}

function Introduction() {
  const { inView, ref, entry } = useInView();
  const animationControl = useAnimation();
  sectionLocations.introduction =
    document.documentElement.scrollTop +
    (entry?.target.getBoundingClientRect().y || 0);

  useEffect(() => {
    if (inView) {
      animationControl.start('show');
    }
  }, [inView, animationControl]);

  return (
    <MotionBox
      initial="hidden"
      variants={parentVariant}
      animate={animationControl}
      w={{ lg: '50%', base: '100%' }}
      bg={{ lg: 'none', base: 'rgba(0, 0, 0, 0.8)' }}
      mt={{ base: 0, md: '200px' }}
      py="8"
      px={{ lg: '10%', base: 0 }}
      position="relative"
    >
      <VStack>
        <Box ref={ref}>
          <SectionHeader text="Introduction" />
          <Box
            color="#6e7c83"
            fontSize="lg"
            py="8"
            px={{ base: '8', lg: 0 }}
            fontWeight="400"
          >
            <MotionBox variants={getChildVariant(0)}>
              The year is 2231. The Earth Federation and its neighboring
              colonies have been at peace for decades.{' '}
              <chakra.span color="#edf2f4">Heavy Suits</chakra.span> (HS),
              originally designed to be weapons of war that can protect their
              pilots in the hazardous conditions outside of the domed cities,
              have been all but forgotten by most of humanity.
            </MotionBox>
            <MotionBox mt="8" variants={getChildVariant(1)}>
              A small group of techno-tinkerers and HS adrenaline junkies
              continue to design and pilot these giant machines to keep the art
              alive. Every year, they meet up in the ruins of Old Manhattan to
              engage in combat and earn the title of the{' '}
              <Image
                src="/images/ace-of-spades.svg"
                alt="Ace"
                display="inline-block"
                filter="drop-shadow(0 0 4px #e5383b)"
                p="4px"
              />
              <chakra.span fontWeight="700" color="#edf2f4">
                Ace of Spades
              </chakra.span>
              .
            </MotionBox>
            <MotionBox mt="8" variants={getChildVariant(2)}>
              <Image src="/images/c.png" height="350px" />
            </MotionBox>
          </Box>
        </Box>
      </VStack>
    </MotionBox>
  );
}

function Feature(props: { icon: string; header: string; description: string }) {
  return (
    <VStack
      width="300px"
      spacing="2"
      transitionDuration="400ms"
      background="rgba(0, 0, 0, 0.15)"
      p="8"
      _hover={{
        background: 'rgba(0, 0, 0, 0.9)',
        boxShadow: '0 0 6px rgba(0, 0, 0, 0.9)',
      }}
    >
      <Image src={`/images/feature-${props.icon}.svg`} height="28px" />
      <Box
        textAlign="center"
        color="#edf2f4"
        textTransform="uppercase"
        fontWeight="700"
        pt="4"
      >
        {props.header}
      </Box>
      <Box textAlign="center">{props.description}</Box>
    </VStack>
  );
}

const features = [
  <Feature
    icon="design"
    header="Millions of possible designs"
    description="Every Heavy Suit NFT comes with its own unique rigged 3D model that you have full access to."
  />,
  <Feature
    icon="stats"
    header="Unique stats and equipment"
    description="Missile launchers, advanced sensor arrays, jetpacks, jamming devices, stealth packages and more!"
  />,
  <Feature
    icon="upgrade"
    header="Upgrade and customize"
    description="Combine parts and equipment from multiple tokens to create more powerful builds."
  />,
  <Feature
    icon="fair"
    header="Provably fair distribution"
    description="The hash of every Suit will be published before the sale and the distribution will be random."
  />,
  <Feature
    icon="ethereum"
    header="Powered by Ethereum"
    description="Your personal HS ownership is secured by the blockchain."
  />,
];

function Features() {
  const { inView, ref, entry } = useInView();
  const animationControl = useAnimation();
  sectionLocations.features =
    document.documentElement.scrollTop +
    (entry?.target.getBoundingClientRect().y || 0);

  useEffect(() => {
    if (inView) {
      animationControl.start('show');
    }
  }, [inView, animationControl]);

  return (
    <MotionBox
      initial="hidden"
      variants={parentVariant}
      animate={animationControl}
      display="flex"
      justifyContent="flex-end"
      bg={{ lg: 'none', base: 'rgba(0, 0, 0, 0.8)' }}
      mt={{ base: 0, md: '20vh' }}
      py="8"
      px={{ lg: '10%', base: 0 }}
    >
      <Box ref={ref} w={{ lg: '50%', base: '100%' }}>
        <SectionHeader text="Features" />
        <VStack>
          <Wrap
            spacing="20px"
            m="4"
            width={{ base: '100%', md: '620px' }}
            justify="center"
          >
            {features.map((f, i) => (
              <MotionBox key={i} variants={getChildVariant(i)}>
                {f}
              </MotionBox>
            ))}
          </Wrap>
        </VStack>
      </Box>
    </MotionBox>
  );
}

function Hanger() {
  const { inView, ref, entry } = useInView();
  const { isOpen, onClose } = useDisclosure();
  const animationControl = useAnimation();
  sectionLocations.hangar =
    document.documentElement.scrollTop +
    (entry?.target.getBoundingClientRect().y || 0);

  useEffect(() => {
    if (inView) {
      animationControl.start('show');
    }
  }, [inView, animationControl]);

  return (
    <>
      <MotionBox
        initial="hidden"
        variants={parentVariant}
        animate={animationControl}
        w={{ lg: '460px', base: '100%' }}
        bg={{ lg: 'none', base: 'rgba(0, 0, 0, 0.8)' }}
        py="8"
        mt={{ base: 0, md: '20vh' }}
        mx={{ lg: '10%', base: 0 }}
      >
        <Box ref={ref} />
        <SectionHeader text="Hangar" />
        <Box
          color="#6e7c83"
          fontSize="lg"
          py="8"
          fontWeight="400"
          px={{ base: '8', lg: 0 }}
        >
          <MotionBox variants={getChildVariant(0)}>
            View your Heavy Suit collection in the hangar. Here you can inspect
            the specs and equipment for each of your HS.
          </MotionBox>
          <MotionBox variants={getChildVariant(0)} pt="4">
            <Button
              as={RouterLink}
              to="/preview/7"
              colorScheme="red"
              bg="brand.red"
            >
              View Preview Suits
            </Button>
          </MotionBox>
        </Box>
        <Wrap spacing={{ base: 0, lg: '2' }}>
          <MotionBox
            variants={getChildVariant(1)}
            width={{ base: '50%', lg: '200px' }}
          >
            <Image src="https://storage.googleapis.com/hs-metadata/preview/4.png" />
          </MotionBox>
          <MotionBox
            variants={getChildVariant(2)}
            width={{ base: '50%', lg: '200px' }}
          >
            <Image src="https://storage.googleapis.com/hs-metadata/preview/6.png" />
          </MotionBox>
          <MotionBox
            variants={getChildVariant(3)}
            width={{ base: '50%', lg: '200px' }}
          >
            <Image src="https://storage.googleapis.com/hs-metadata/preview/3.png" />
          </MotionBox>
          <MotionBox
            variants={getChildVariant(4)}
            width={{ base: '50%', lg: '200px' }}
          >
            <Image src="https://storage.googleapis.com/hs-metadata/preview/2.png" />
          </MotionBox>
        </Wrap>
      </MotionBox>
      <Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap>
        <ModalOverlay />
        <ModalContent background="#141313">
          <Wrap>
            <Image src="/images/gallery/centurion.png" alt="Centurion" />
            <Image src="/images/gallery/m6blue.png" alt="Centurion" />
            <Image src="/images/gallery/mowang2.jpg" alt="Centurion" />
            <Image src="/images/gallery/pinkm4.png" alt="Centurion" />
            <Image src="/images/gallery/valiant.png" alt="Centurion" />
          </Wrap>
        </ModalContent>
      </Modal>
    </>
  );
}

function Milestone(props: {
  step: string;
  header: string;
  description: string;
}) {
  return (
    <HStack
      spacing="8"
      p="4"
      background="rgba(0, 0, 0, 0)"
      transitionDuration="400ms"
      _hover={{
        background: 'rgba(0, 0, 0, 0.9)',
        boxShadow: '0 0 6px rgba(0, 0, 0, 0.9)',
      }}
    >
      <Box fontSize="4xl" fontWeight="700" color="#e5383b">
        {props.step}
      </Box>

      <VStack spacing="2" alignItems="flex-start">
        <Box color="#edf2f4" fontSize="xl" fontWeight="700" pt="4">
          {props.header}
        </Box>
        <Box>{props.description}</Box>
      </VStack>
    </HStack>
  );
}

function Roadmap() {
  const { inView, ref, entry } = useInView();
  const animationControl = useAnimation();
  sectionLocations.roadmap =
    document.documentElement.scrollTop +
    (entry?.target.getBoundingClientRect().y || 0);

  useEffect(() => {
    if (inView) {
      animationControl.start('show');
    }
  }, [inView, animationControl]);

  return (
    <MotionBox
      initial="hidden"
      variants={parentVariant}
      animate={animationControl}
      w={{ lg: '50%', base: '100%' }}
      bg={{ lg: 'none', base: 'rgba(0, 0, 0, 0.8)' }}
      mt={{ base: 0, md: '20vh' }}
      py="8"
      pb={{ base: 0, md: '20vh' }}
      px={{ lg: '10%', base: 0 }}
    >
      <Box ref={ref} />
      <SectionHeader text="Roadmap" />
      <VStack>
        <Wrap
          spacing="20px"
          m="4"
          width={{ base: '100%', md: '600px' }}
          justify="center"
        >
          <MotionBox variants={getChildVariant(0)}>
            <Milestone
              step="1"
              header="Initial Suit Minting"
              description="At launch, every one of the 7777 minted Generation-1 suits will come with its own unique 3D model, along with specific attributes and parts, all viewable in your personal hanger."
            />
          </MotionBox>
          <MotionBox variants={getChildVariant(1)}>
            <Milestone
              step="2"
              header="Mechanics-for-Hire"
              description="Due to the scarcity of HS components in 2231, pilots have to customize their suits by swapping out parts from other existing suits. This will be done through a token re-minting process by recombining parts from two or more suits."
            />
          </MotionBox>
          <MotionBox variants={getChildVariant(2)}>
            <Milestone
              step="3"
              header="Designer Reward Program"
              description="Submit original part and suit designs and earn royalty on every mint that uses your designs! We will provide instructions on how to make your 3D models work with the Heavy Suit platform."
            />
          </MotionBox>
          <MotionBox variants={getChildVariant(3)}>
            <Milestone
              step="4"
              header="Ship of Theseus"
              description="As suits get scavenged for parts, new generation of suits will become mintable with some old Generation-1 parts rotating out of circulation. Suits from the initial mints will always be marked as Generation-1 suits, even after all their parts have been replaced over time."
            />
          </MotionBox>
          <MotionBox variants={getChildVariant(4)}>
            <Milestone
              step="5"
              header="Battle in Old Manhattan"
              description="Test your unique personal suits in one-on-one combat against other pilots! Some combination of parts may look better than they perform on the battlefield..."
            />
          </MotionBox>
        </Wrap>
        <MotionBox
          variants={getChildVariant(0)}
          display="flex"
          justifyContent="center"
        >
          <Image src="/images/a.png" height="350px" transform="scale(-1, 1)" />
        </MotionBox>
      </VStack>
    </MotionBox>
  );
}

function HomeRoute() {
  return (
    <>
      <Box fontFamily="Rogan" width="100%" color="#6e7c83" overflowX="hidden">
        <HeroUnit />
        <Introduction />
        <Features />
        <Hanger />
        <Roadmap />

        <MotionBox
          variants={parentVariant}
          position="fixed"
          top="4"
          left="50%"
          transform="translate(-50%)"
          opacity="0.8"
        >
          <Countdown />
        </MotionBox>
        <MotionBox variants={parentVariant} initial="hidden" animate="show">
          <Logo />
          <Header />
        </MotionBox>
      </Box>
      <BackgroundCanvas />
    </>
  );
}

export default HomeRoute;
