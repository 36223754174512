import { Box, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Attribute } from '../shared/TokenMetadata';
import { romanize } from './romanize';

export function AttributeDisplay(props: { attribute: Attribute }) {
  const a = props.attribute;
  let value = a.value;
  if (a.trait_type === 'Date of Manufacture') {
    value = format(new Date(value), 'MMM dd, yyyy');
  } else if (a.trait_type === 'Mark') {
    value = romanize(value as any);
  } else if (a.trait_type === 'Height') {
    value = `${value}m`;
  } else if (a.trait_type === 'Weight') {
    value = `${value} tons`;
  }
  return (
    <Box>
      <Text
        fontSize={{ base: '6pt', lg: 'sm' }}
        textTransform="uppercase"
        color="#6e7c83"
      >
        {a.trait_type}
      </Text>
      <Text fontSize={{ base: '9pt', lg: 'lg' }}>{value}</Text>
    </Box>
  );
}
