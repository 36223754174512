import axios from 'axios';
import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import { hs } from '../HeavySuit';
import { TokenMetadata } from '../shared/TokenMetadata';

export interface SuitData {
  id: BigNumber;
  name: string;
  metadata: TokenMetadata | null;
}

export function useSuitVersion(
  batch: string,
  version: string,
): SuitData | null {
  const [suit, setSuit] = useState<SuitData | null>(null);

  useEffect(() => {
    const load = async () => {
      const url = `https://storage.googleapis.com/hs-metadata/${batch}/${version}.json`;
      try {
        const response = await axios.get(url);
        const metadata = response.data as TokenMetadata;
        setSuit({
          id: BigNumber.from(0),
          name: metadata.name,
          metadata,
        });
      } catch (error) {
        setSuit({
          id: BigNumber.from(0),
          name: `Heavy Suit`,
          metadata: null,
        });
      }
    };
    load();
  }, [version, batch]);

  return suit;
}

export function useSuit(id: BigNumber): SuitData | null {
  const [suit, setSuit] = useState<SuitData | null>(null);
  const [tokenId, setTokenId] = useState<BigNumber>(BigNumber.from(-1));

  useEffect(() => {
    if (!id.eq(tokenId)) {
      setTokenId(id);
    }
  }, [id, setTokenId, tokenId]);

  useEffect(() => {
    if (tokenId.lt(0)) {
      return;
    }
    const load = async () => {
      const url = await hs.tokenURI(tokenId);
      try {
        const response = await axios.get(url);
        const metadata = response.data as TokenMetadata;
        setSuit({
          id: tokenId,
          name: metadata.name,
          metadata,
        });
      } catch (error) {
        setSuit({
          id: tokenId,
          name: `Heavy Suit #${tokenId}`,
          metadata: null,
        });
      }
    };
    load();
  }, [tokenId]);

  return suit;
}
