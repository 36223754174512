import { Box, Button, HStack, Icon, Link } from '@chakra-ui/react';
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

function Header() {
  return (
    <HStack
      px={{ base: '4', md: '8' }}
      py={{ base: '2', md: '4' }}
      position="fixed"
      top="0"
      right="0"
      left="0"
      zIndex="4"
      spacing="4"
      justifyContent="flex-end"
      background="linear-gradient(180deg, rgba(20, 19, 19, 0.8) 50%, transparent 100%)"
      pointerEvents="auto"
    >
      <Box mr={{ base: 0, md: '8' }}>
        {window.location.href.includes('/preview') ? (
          <Button
            as={RouterLink}
            to="/"
            variant="link"
            colorScheme="red"
            color="brand.red"
          >
            Home
          </Button>
        ) : (
          <Button
            as={RouterLink}
            to="/preview/7"
            colorScheme="red"
            variant="link"
            color="brand.red"
          >
            Preview
          </Button>
        )}
      </Box>
      <Link
        transitionDuration="400ms"
        color="#e5383b"
        opacity="0.8"
        _hover={{ opacity: 1.0 }}
        href="https://twitter.com/HeavySuitNFT"
        title="Twitter @HeavySuitNFT"
        isExternal
      >
        <Icon as={FaTwitter} fontSize="3xl" />
      </Link>
      <Link
        transitionDuration="400ms"
        color="#e5383b"
        opacity="0.8"
        _hover={{ opacity: 1.0 }}
        href="https://discord.gg/MNCRA83ny2"
        title="Join us on Discord"
        isExternal
      >
        <Icon as={FaDiscord} fontSize="3xl" />
      </Link>
    </HStack>
  );
}

export default Header;
