import { Box, HStack, Image, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export function Logo() {
  return (
    <Box position="fixed" left="4" top="4" zIndex="20">
      <Link as={RouterLink} to="/" pointerEvents="auto">
        <HStack>
          <Image
            src="/images/logo.svg"
            height={{ base: '20px', md: '36px' }}
            alt="Logo"
          />
          <Image
            src="/images/logo-text.svg"
            height={{ base: '20px', md: '36px' }}
            alt="Heavy Suit"
          />
        </HStack>
      </Link>
    </Box>
  );
}
