import { Box, Image, Wrap } from '@chakra-ui/react';
import { useState } from 'react';
import { useRouteMatch } from 'react-router';

function GalleryItem(props: { url: string; label: string }) {
  const [visible, setVisible] = useState(false);

  return (
    <Box position="relative" height="350px" width="350px" hidden={!visible}>
      <Image src={props.url} onLoad={() => setVisible(true)} />
      <Box
        position="absolute"
        fontSize="xl"
        weight="700"
        color="white"
        zIndex="10"
        top="0"
        left="0"
        background="black"
        px="4"
        py="2"
      >
        {props.label}
      </Box>
    </Box>
  );
}

export function Gallery() {
  const indices = Array.from(Array(7778).keys());
  const route = useRouteMatch<{ batch: string }>();

  return (
    <Wrap>
      {indices.map((i) => {
        return (
          <GalleryItem
            key={i}
            label={`${i}`}
            url={`https://storage.googleapis.com/hs-metadata/${route.params.batch}/thumbnails/${i}.png`}
          />
        );
      })}
    </Wrap>
  );
}
