import { useEffect, useState } from 'react';
import { hs } from '../HeavySuit';

export function useCountdown(): {
  startTimestamp: number;
  duration: number;
  revealTimestamp: number;
  timeToStart: number;
  timeToReveal: number;
  saleStarted: boolean;
  now: number;
} {
  const [startTimestamp, setStartTimestamp] = useState(0);
  const [duration, setDuration] = useState(0);
  const [now, setNow] = useState(Date.now());
  const revealTimestamp = startTimestamp + duration;

  useEffect(() => {
    const id = setInterval(() => {
      setNow(Date.now());
    }, 47);

    return () => {
      clearInterval(id);
    };
  }, [setNow]);

  useEffect(() => {
    const update = async () => {
      try {
        const start = await hs.saleStartTimestamp();
        const d = await hs.duration();
        setStartTimestamp(start.toNumber() * 1000);
        setDuration(d.toNumber() * 1000);
        throw new Error('Not yet');
      } catch (error) {
        setStartTimestamp(1639252497 * 1000);
        setDuration(10 * 60 * 60 * 24 * 1000);
      }
    };
    update();
  }, [setStartTimestamp, setDuration]);

  const timeToStart = Math.max(0, startTimestamp - now);
  const timeToReveal = Math.max(0, revealTimestamp - now);

  return {
    startTimestamp,
    duration,
    revealTimestamp,
    timeToStart,
    timeToReveal,
    saleStarted: timeToStart === 0,
    now,
  };
}
