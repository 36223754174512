import { motion } from 'framer-motion';
import { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import './firebase';
import { Gallery } from './Gallery';
import HomeRoute from './HomeRoute';
import { Loading } from './Loading';
import { PreviewRoute } from './PreviewRoute';
import { SuitRoute, VersionRoute } from './SuitRoute';
import { UserContextProvider } from './UserContext';

export function App() {
  return (
    <UserContextProvider>
      <Suspense fallback={<Loading />}>
        <motion.div
          initial="hidden"
          animate="show"
          variants={{
            hidden: { opacity: 0 },
            show: {
              opacity: 1.0,
              transition: { duration: 1.0, when: 'beforeChildren' },
            },
          }}
        >
          <BrowserRouter>
            <Switch>
              <Route path="/suit/:tokenId" component={SuitRoute} exact />
              <Route
                path="/preview/:metadataId?"
                component={PreviewRoute}
                exact
              />
              <Route
                path="/gallery/:batch/:metadataId"
                component={VersionRoute}
                exact
              />
              <Route path="/gallery/:batch" component={Gallery} exact />
              <Route path="/" component={HomeRoute} />
            </Switch>
          </BrowserRouter>
        </motion.div>
      </Suspense>
    </UserContextProvider>
  );
}
