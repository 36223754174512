import { ethers } from 'ethers';
import { provider } from './ethers';
import artifact from './shared/artifacts/contracts/HeavySuit.sol/HeavySuit.json';
import { HeavySuit } from './shared/typechain';

const { abi } = artifact;
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS ?? '';
if (!contractAddress) {
  // throw new Error('Missing REACT_APP_CONTRACT_ADDRESS');
}
console.log('Contract address:', contractAddress);

export const hs = new ethers.Contract(
  contractAddress,
  abi,
  provider,
) as HeavySuit;

hs.orderFee().then((fee) =>
  console.log(`Suit order fee: ${ethers.utils.formatEther(fee)} ETH`),
);

(window as any).hs = hs;
