import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const underlay = document.getElementById('underlay')!;
const container = document.getElementById('primary-viewer-container')!;
container.style.setProperty('--height', `${window.innerHeight}px`);
container.style.setProperty('--width', `${window.innerWidth}px`);
underlay.style.setProperty('--height', `${window.innerHeight}px`);
underlay.style.setProperty('--width', `${window.innerWidth}px`);

window.addEventListener('resize', () => {
  container.style.setProperty('--height', `${window.innerHeight}px`);
  container.style.setProperty('--width', `${window.innerWidth}px`);
  underlay.style.setProperty('--height', `${window.innerHeight}px`);
  underlay.style.setProperty('--width', `${window.innerWidth}px`);
});

const theme = extendTheme({
  colors: {
    brand: {
      black: '#141313',
      red: '#e5383b',
      white: '#edf2f4',
      gray: '#6e7c83',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
