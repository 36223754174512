import { Box, Flex, Image, Stack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Link, useRouteMatch } from 'react-router-dom';
import Header from './Header';
import { useSuitVersion } from './hooks/useSuit';
import { Logo } from './Logo';
import { SuitContainer } from './viewer/SuitContainer';

const MotionBox = motion(Box);

const PREVIEW_IDS = [7, 4, 5, 1, 2, 3, 6];

function PreviewThumbnail({
  metadataId,
  selected,
}: {
  metadataId: number;
  selected: boolean;
}) {
  const suit = useSuitVersion('preview', metadataId.toString());
  return (
    <Link to={`/preview/${metadataId}`}>
      <Image
        minWidth="84px"
        width={{ base: '84px', lg: '200px' }}
        height={{ base: '84px', lg: '200px' }}
        src={suit?.metadata?.image || ''}
        transitionDuration="200ms"
        opacity={selected ? 1.0 : 0.8}
        _hover={{ opacity: 1.0, borderColor: '#edf2f4' }}
        borderStyle="solid"
        borderWidth="1px"
        borderColor={selected ? '#6e7c83' : 'transparent'}
      />
    </Link>
  );
}

export function PreviewRoute() {
  const route = useRouteMatch<{ metadataId?: string }>();
  const { metadataId } = route.params;

  return (
    <MotionBox
      transitionDelay="200ms"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      fontFamily="Rogan"
      color="#6e7c83"
      height="100vh"
    >
      <Stack
        flexDirection={{ base: 'column', lg: 'row' }}
        spacing="0"
        height="100%"
        width="100vw"
      >
        <Box bg="#141313" pl="4" pt={{ base: '42px', md: '60px', lg: '100px' }}>
          <Box fontSize={{ base: 'md', lg: 'lg' }} fontWeight="400">
            Preview Suits (7 of 7777)
          </Box>
          <Box
            maxWidth={{ base: 'auto', lg: '240px' }}
            maxHeight={{ base: 'auto', lg: 'calc(100% - 40px)' }}
            overflowY={{ base: 'hidden', lg: 'auto' }}
            overflowX={{ base: 'auto', lg: 'hidden' }}
            pointerEvents="auto"
            pr="4"
            pb={{ base: '2', lg: 0 }}
            className="scrollbar"
          >
            <Flex flexDirection={{ base: 'row', lg: 'column' }}>
              {PREVIEW_IDS.map((id) => (
                <PreviewThumbnail
                  key={id}
                  metadataId={id}
                  selected={id.toString() === metadataId}
                />
              ))}
            </Flex>
          </Box>
        </Box>
        <>
          {metadataId ? (
            <SuitContainer batch="preview" metadataId={metadataId} />
          ) : (
            <Box pt={{ base: 0, lg: '100px' }}>
              <Box color="#141313" fontWeight="700" fontSize="xl" py="2" px="4">
                Select a suit to view
              </Box>
            </Box>
          )}
        </>
      </Stack>
      <Logo />
      <Header />
    </MotionBox>
  );
}
